import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { PortableText } from "@portabletext/react";
import type { PortableTextBlock } from "@portabletext/types";
import styled from "styled-components";
import { Box, Heading } from "grommet";
import HubSpotSectionContent from "@components/HubSpotSectionContent";

import Seo from "@components/Seo";
import Text from "@components/Text";
import Paragraph from "@components/Paragraph";
import SanityImage from "@components/SanityImage";
import Section from "@components/Section";
import CustomerStoriesCarousel from "@components/CustomerStoriesCarousel";
import PageHero from "@components/PageHero";
import ResponsiveContainerBox from "@components/ResponsiveContainerBox";
import { defaultComponents } from "@utils/PortableText";
import {
  getMobileBreakpoint,
  getDesktopBreakpoint,
  ThickPaddedContainer,
  getTextBreakpoint,
  SecondaryFont,
} from "@utils/Grommet";

const HeroContent = styled(Box)`
  ${ThickPaddedContainer}

  padding-bottom: 10px;
  padding-right: 50px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-right: 25px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-left: 0px;
    padding-right: 0px;

    /* max-width: 350px; */
  }
`;

const TwoColumnBox = styled(Box)`
  ${ThickPaddedContainer}

  flex-direction: row;

  & > *:first-child {
    width: 255px;
    margin-right: 36px;
  }

  & > *:last-child {
    flex: 1;
    max-width: 540px;
  }

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-left: 0px;
    padding-right: 0px;

    & > *:first-child {
      width: 210px;
      margin-right: 30px;
    }

    & > *:last-child {
      max-width: 530px;
    }
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    flex-direction: column;
    align-items: center;

    & > *:first-child {
      width: 100%;
      max-width: 530px;
      margin-right: 0px;
    }
  }
`;

const QuoteText = styled(Text)`
  font-style: italic;

  font-size: 22px;
  line-height: 34px;

  @media (max-width: ${getTextBreakpoint}px) {
    font-size: 18px;
    line-height: 30px;
  }

  ${SecondaryFont}
`;

type TSolutionQuery = Queries.CustomerStoryQuery & {
  story: {
    _rawStory?: PortableTextBlock[];
    _rawStorySideNotes?: PortableTextBlock[];
    _rawResults?: PortableTextBlock[];
    _rawResultsSideNotes?: PortableTextBlock[];
  };
};

const CustomerStory = ({ data, location }: PageProps<TSolutionQuery>) => {
  const site = data?.site;
  const story = data?.story;
  const otherStories = data?.otherStories?.edges?.map((edge) => edge?.node);

  const ogImage = story?.cardImage?.image?.asset?.url;

  return (
    <Box>
      <Seo
        title={story?.title ?? ""}
        description={story?.metaDescription ?? undefined}
        ogImage={ogImage}
        ogUrl={location?.href}
      />
      <PageHero seoImage={story?.heroImage}>
        <HeroContent align="start">
          <Text weight={500} margin={{ bottom: "xxsmall" }}>
            Customer Stories
          </Text>

          <Box margin={{ bottom: "small" }}>
            <SanityImage
              {...story?.logo?.image}
              alt={story?.logo?.alt}
              imgwidth={`${story?.logo?.maxWidth}px`}
            />
          </Box>

          <Heading level={1} color="grey-10" margin={{ bottom: "xsmall" }}>
            {story?.title}
          </Heading>
          <Paragraph size="large">{story?.intro}</Paragraph>
        </HeroContent>
      </PageHero>

      <Section constrained="hd" pad={{ vertical: "large" }}>
        <TwoColumnBox gap="medium">
          <Box>
            <PortableText
              value={story?._rawStorySideNotes}
              components={defaultComponents({
                hLevel: 4,
                tSize: "small",
                hMargin: "unset",
              })}
            />
          </Box>
          <Box>
            <Box
              pad={{ bottom: "small" }}
              margin={{ bottom: "medium" }}
              border={{ side: "bottom", color: "grey-6" }}
            >
              <PortableText
                value={story?._rawStory}
                components={defaultComponents({
                  hLevel: 2,
                  hSize: "small",
                })}
              />
            </Box>
            <Box>
              <QuoteText margin={{ bottom: "xxsmall" }}>
                {story?.mainQuote?.body}
              </QuoteText>
              <Text weight={500} size="small" margin={{ bottom: "small" }}>
                {story?.mainQuote?.author}
              </Text>
            </Box>
          </Box>
        </TwoColumnBox>
      </Section>

      <Section
        constrained="hd"
        pad={{ vertical: "xlarge" }}
        background="grey-10"
      >
        <TwoColumnBox gap="medium">
          <Box>
            <PortableText
              value={story?._rawResultsSideNotes}
              components={defaultComponents({
                hLevel: 4,
                tSize: "small",
                hMargin: "unset",
              })}
            />
          </Box>

          <Box>
            <Box>
              <PortableText
                value={story?._rawResults}
                components={defaultComponents({
                  hLevel: 2,
                  hSize: "small",
                })}
              />
            </Box>

            {story?.resultsQuotes && story?.resultsQuotes?.length > 0 && (
              <Box
                gap="xsmall"
                pad={{ top: "medium" }}
                margin={{ top: "small" }}
                border={{ side: "top", color: "grey-6" }}
              >
                {story?.resultsQuotes?.map((quote, index) => (
                  <Box key={index}>
                    <QuoteText margin={{ bottom: "xxsmall" }}>
                      "{quote?.body}"
                    </QuoteText>
                    <Text
                      weight={500}
                      size="small"
                      margin={{ bottom: "small" }}
                    >
                      {quote?.author}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </TwoColumnBox>
      </Section>

      {otherStories && otherStories?.length > 0 && (
        <Section pad={{ top: "xlarge" }}>
          <ResponsiveContainerBox
            constrained="hd"
            padded="thick"
            margin={{ bottom: "large" }}
          >
            <Heading level={2}>More Customer Stories</Heading>
          </ResponsiveContainerBox>

          <CustomerStoriesCarousel customerStories={otherStories} />
        </Section>
      )}

      <Section constrained="hd" background="brand">
        <HubSpotSectionContent
          hsPortalId={site?.hsPortalId}
          hsFormId={site?.hsFormId}
        />
      </Section>
    </Box>
  );
};

export const query = graphql`
  query CustomerStory($slug: String!) {
    story: sanityCustomerStory(slug: { current: { eq: $slug } }) {
      _id
      id

      title
      metaDescription
      cardImage {
        ...SeoOgImage
      }

      intro
      heroImage {
        ...PageHero
      }
      logo {
        image {
          ...ImageWithPreview
        }
        alt
        maxWidth
      }

      slug {
        current
      }

      _rawStory(resolveReferences: { maxDepth: 10 })
      _rawStorySideNotes
      _rawResults
      _rawResultsSideNotes

      mainQuote {
        body
        author
      }

      resultsQuotes {
        body
        author
      }
    }

    otherStories: allSanityCustomerStory(
      filter: { slug: { current: { ne: $slug } } }
    ) {
      edges {
        node {
          ...CustomerStoriesCarousel
        }
      }
    }

    site: sanitySite {
      _id
      id

      ...HubSpotForm
    }
  }
`;

export default CustomerStory;
